
import Kirbyimage from "./kirbyimage.vue";
import KirbyLink from "./kirbylink.vue";
export default {
  name: "LinksAndTestimonial",
  props: {
    content: Object,
  },
  data() {
    return {
      slides: [],
      pageCount: 0,
      currentIndex: 0,
      showNav: false,
      currentSlide: null,
    };
  },
  methods: {
    updateIndex(pageIndex) {
      if (pageIndex < 0) {
        pageIndex = this.slides.length - 1;
      } else if (pageIndex >= this.slides.length) {
        pageIndex = 0;
      }

      this.currentIndex = pageIndex;
      this.setCurrentSlide(pageIndex);
    },
    setCurrentSlide(index) {
      this.currentSlide = this.slides[index];
    },
    prevSlide() {
      this.updateIndex((this.currentIndex -= 1));
    },
    nextSlide() {
      this.updateIndex((this.currentIndex += 1));
    },
  },
  mounted() {
    this.slides = this.content.testimonials;
    this.pageCount = this.slides.length;
    this.showNav = this.slides.length > 1;
    this.setCurrentSlide(0);
  },
  components: {
    KirbyLink,
    Kirbyimage,
  },
};
